export const CONSUMER_APP_ID = '02ff1a88-a969-4718-b2a0-4dcf2d8a2084'
export const CONSUMER_INSTANCE_ID = '0468cd79-e4f1-48e9-86bb-a09a8a1bf265'

export const DEMO_PRODUCER_APP_ID = 'f0ce5c65-f571-4cda-b038-c9ceae0fb3d4'
export const ARCHITECT_AUDIENCES_APP_ID = '2a74572b-f6dd-4002-aedf-c5c9186af8c5'

export interface ConsumerConnectionInfo {
  connectionId: string
  dataType: string
  sourceAppId: string
  sourceAppInstanceId: string
  sourceBaseUrl: string
}

export interface ProducerConnectionInfo {
  connectionId: string
  dataType: string
  targetAppId: string
  targetAppInstanceId: string
}

export interface ConnectionsContext {
  asProducer: ProducerConnectionInfo[]
  asConsumer: ConsumerConnectionInfo[]
}

export interface AppInfoContext {
  appName: string
  appId: string
  appInstanceId: string
}

export interface AppContext {
  appName: string
  appId: string
  appInstanceId: string
  preciousBaseUrl: string
  connections: {
    asProducer: ProducerConnectionInfo[]
    asConsumer: ConsumerConnectionInfo[]
  }
}
