import { WppInlineMessage } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import axios from 'axios'
import React from 'react'

import { DataObjectItem, AudienceContainerData } from 'components/DataObjectItem/DataObjectItem'
import { EmptyObjectsList } from 'components/EmptyObjectsList/EmptyObjectsList'
import { LoadingComponent } from 'components/EmptyObjectsList/LoadingComponent'
import { environment } from 'environment'
import styles from 'pages/audienes/AudiencesPage.module.scss'

import {
  ConsumerConnectionInfo,
  AppInfoContext,
  ConnectionsContext,
  CONSUMER_APP_ID,
  CONSUMER_INSTANCE_ID,
  DEMO_PRODUCER_APP_ID,
  ARCHITECT_AUDIENCES_APP_ID,
} from './config'
import { getAppContext } from './getContext'

const genders: { [index: string]: string } = {
  '1': 'male',
  '2': 'female',
}

export const AudiencesPage = () => {
  // const { firstname, lastname, country, agency, email, id } = osContext.userDetails
  const { osContext, osApi } = useOs()

  const appInfoContext: AppInfoContext = {
    appName: 'Consumer',
    appId: CONSUMER_APP_ID,
    appInstanceId: CONSUMER_INSTANCE_ID,
  }

  const config = {
    headers: {
      Authorization: `Bearer ${osApi.getAccessToken()}`,
      Workspace: osContext.workspace && osContext.workspace.azId,
    },
  }
  const [isLoading, setIsLoading] = React.useState(true)
  const [serializedAudiences, setSerializedAudiences] = React.useState<AudienceContainerData[]>([])
  const [showErrorMessage, setShowErrorMessage] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('error occurred')

  const setConnectionConfigCallback = (response: {
    data: { connections: ConnectionsContext }
    isError: boolean
    message: string
  }) => {
    getAudiencesFromConnections(response.data.connections.asConsumer)
  }
  React.useEffect(
    () => getAppContext(appInfoContext.appId, appInfoContext.appInstanceId, config, setConnectionConfigCallback),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const checkFailed = (then: any) => {
    return function (responses: any) {
      const failedApps: string[] = []
      then(responses.filter((res: any) => !res.error))
      responses.forEach((response: any) => {
        if (response.error) failedApps.push(response.error.config.headers.appName)
      })
      if (failedApps.length > 0) {
        setErrorMessage(`can't get data from apps: ${failedApps.join(', ')}`)
        setShowErrorMessage(true)
      }
    }
  }

  const getAudiencesFromConnections = (consumerConnections: ConsumerConnectionInfo[]) => {
    if (consumerConnections.length === 0) {
      setErrorMessage('data connections list is empty')
      setShowErrorMessage(true)
      setIsLoading(false)
      return
    }

    const requests = consumerConnections.map((item: ConsumerConnectionInfo) =>
      axios.get(`${environment.preciousServiceUrl}/api/v1/data/${item.connectionId}`, {
        ...config,
        headers: { ...config.headers, appName: getAppName(item.sourceAppId) },
      }),
    )

    const resolvedPromises = requests.map(promise => promise.catch(error => ({ error })))

    setIsLoading(true)
    setShowErrorMessage(false)
    setSerializedAudiences([])

    axios
      .all(resolvedPromises)
      .then(checkFailed(mergeAudiencesResponses))
      .catch(error => handleError(error))
  }

  const handleError = (error: any) => {
    console.error('[ERROR OCCURRED]', error)
    setIsLoading(false)
  }

  const getAppName = (appId: string | undefined): string => {
    let name

    switch (appId) {
      case DEMO_PRODUCER_APP_ID:
        name = 'Demo Data Producer'
        break
      case ARCHITECT_AUDIENCES_APP_ID:
        name = 'Architect - Audiences'
        break
      default:
        name = 'unknown app'
    }

    return name
  }

  const prepareAudienceContainerData = (audienceOrigin: any, sourceAppName: string): AudienceContainerData => {
    let audience: AudienceContainerData = {
      id: audienceOrigin.id,
      name: audienceOrigin.name,
      description: audienceOrigin.description,
      imagePath: `/_assets/audiences/icons/${audienceOrigin.imagePath}`,
      level: audienceOrigin.level,
      definitionsDataTypes: [],
      budget: `$${audienceOrigin.averagePurchaseValue * audienceOrigin.numberOfPurchases}`,
      objectType: 'audience',
      sourceName: sourceAppName,
    }

    if (audienceOrigin.definitions.length > 0) {
      audience.definitionsDataTypes = audienceOrigin.definitions.map((item: any) => item.dataType)

      if (
        audienceOrigin.definitions[0].filterTree &&
        audienceOrigin.definitions[0].filterTree.filters &&
        audienceOrigin.definitions[0].filterTree.filters[0].filterField === 'Q02_Gender'
      ) {
        const genderFilterValue = audienceOrigin.definitions[0].filterTree.filters[0].filterValue as string
        audience.gender = genders[genderFilterValue]
      }
      if (
        audienceOrigin.definitions[0].filterTree &&
        audienceOrigin.definitions[0].filterTree.filters &&
        audienceOrigin.definitions[0].filterTree.filters.length > 1 &&
        audienceOrigin.definitions[0].filterTree.filters[1].filterField === 'DEM02'
      ) {
        audience.hasChildren = audienceOrigin.definitions[0].filterTree.filters[1].filterValue === '1' ? 'yes' : 'no'
      }
    }
    return audience
  }

  const mergeAudiencesResponses = (responses: any) => {
    console.log('[AUDIENCES RECEIVED]', responses)
    setIsLoading(false)
    let mergedAudiences: AudienceContainerData[] = []

    for (let response of responses) {
      let serializedAudiences = response.data.data.map((item: any) =>
        prepareAudienceContainerData(item, response.config.headers.appName),
      )
      mergedAudiences = [...mergedAudiences, ...serializedAudiences]
    }
    setSerializedAudiences(mergedAudiences)
  }

  const reloadAudiences = () => {
    setIsLoading(true)
    getAppContext(appInfoContext.appId, appInfoContext.appInstanceId, config, setConnectionConfigCallback)
  }

  return (
    <>
      <h1 className={styles.objectTitle}>Audiences</h1>
      {isLoading ? (
        <LoadingComponent />
      ) : serializedAudiences.length > 0 ? (
        <div className={styles.cards}>
          {serializedAudiences.map((item: any) => (
            <DataObjectItem key={item.id} itemData={item} />
          ))}
        </div>
      ) : (
        <EmptyObjectsList
          primaryText="Loading your linked audiences"
          secondaryText="Press get in order to get a list of found audiences"
          buttonText="get audiences"
          callback={reloadAudiences}
        />
      )}
      {showErrorMessage && <WppInlineMessage size="s" message={errorMessage} type="error" showTooltipFrom={300} />}
    </>
  )
}
